import React from "react";
import "./dashboard.css";
import home from "./../assets/home.svg";
import car from "./../assets/car.svg";
import bike from "./../assets/bike.svg";
import truck from "./../assets/truck.svg";
import health from "./../assets/health.svg";
import life from "./../assets/life.svg";
import pr from "./../assets/pr.svg";
import oneplace from "./../assets/oneplace.svg";
import match from "./../assets/match.svg";
import support from "./../assets/support.svg";
import logo from "./../assets/logo1.svg";
// import Popup from "../components/popup/popup";

export default function DashBoard(props: any) {
  return (
    <div className="dash">
      <div className="desc">
        <h1>Simplifying the way you </h1>
        <h1>buy insurance</h1>
        <br />
        <p>You tell us about your vehicle. We find the best coverage with the lowest price. Now, and every year.</p>
      </div>

      <div>
        <h2 style={{ textAlign: "center" }}>Select a Product</h2>
        <div className="products">
          <div style={{ width: "24vw" }}>
            <img src={car} alt="" />
            <p>Car Insurance</p>
          </div>
          <div>
            <img src={bike} alt="" />
            <p>Bike Insurance</p>
          </div>
          <div>
            <img src={truck} alt="" />
            <p>Commercial Insurance</p>
          </div>
          <div>
            <img src={health} alt="" />
            <p>Health Insurance</p>
          </div>
          <div>
            <img src={life} alt="" />
            <p>Life Insurance</p>
          </div>
          <div style={{ width: "24vw" }}>
            <img src={home} alt="" />
            <p>Home Insurance</p>
          </div>
        </div>
      </div>

      <div className="quotes">
        <div style={{ height: "2vw" }}></div>
        <img style={{ width: "85%", paddingTop: "1vw", paddingBottom: "4vw" }} src={pr} alt="" />
        <h1>Get personalized quotes</h1>
        <h1>in 5 minutes</h1>
        <p>No matter where you live or what you drive, we find the policies that are right for you.</p>
        <div className="insights">
          <div>
            <img className="insight-box" src={oneplace} alt="" />
            <span className="insight-content">
              <h2>One place for every major provider</h2>
              <p>No need to look around. Review every Major provider side-by-side in one place</p>
            </span>
          </div>

          <div>
            <span className="insight-content">
              <h2>Find a match and Save hundreds every year</h2>
              <p>Whether you’re looking to save on a car or Bike insurance, we find the best valuator you.</p>
            </span>
            <img className="insight-box" src={match} alt="" />
          </div>
          <div>
            <img className="insight-box" src={support} alt="" />
            <span className="insight-content">
              <h2>We’ve got you Covered</h2>
              <p>Our agents are always standing by Help you as your needs change</p>
            </span>
          </div>
        </div>
        <div style={{ zIndex: 5, backgroundColor: "#EBEBEB", height: "20vw", display: "flex", flexDirection: "column", justifyContent: "center" }}>
          <h2 style={{ fontSize: "3vw" }}>Get the best deal now, and every year</h2>
          <button onClick={props.close} style={{ border: "0" }}>
            <p>
              <span
                style={{
                  backgroundColor: "red",
                  padding: "1vh 2vh",
                  color: "white",
                  border: "0",
                  fontSize: "1.5vw",
                  borderRadius: "8px",
                }}
              >
                Request a Quote
              </span>
            </p>
          </button>
        </div>
      </div>
      <div className="info">
        <div className="a">
          <img style={{ width: "30%", paddingTop: "2vh" }} src={logo} alt="" />
          <h2 style={{ margin: "0", fontSize: "2vh" }}>S A Capital Insurance Solutions</h2>
        </div>
        <br />
        <br />
        <div className="a">
          <h3>Contact</h3>
          <p>#24, 1st Floor, Dr. Rajkumar Road, Opp, Java Showroom, Rajajinagar 4th 'N' Block, Bengaluru 560010</p>
        </div>
        <br />
        <br />
        <div className="a">
          <h3>Phone Number</h3>
          <p>+91 99648 65358</p>
          <h3>Email</h3>
          <p>anilskumar@sacapital.co.in</p>
        </div>
        <br />
        <br />
        <div className="a" id="social">
          <h3>Social</h3>
          <div style={{ margin: "1vw 0", display: "flex", flexDirection: "row", justifyContent: "left" }}>
            <img style={{ height: "3.5vh", width: "3.5vh" }} src="https://seeklogo.net/wp-content/uploads/2016/05/instagram-icon-logo-vector-download-400x400.jpg" alt="" />
            <p style={{ paddingLeft: "3vh", margin: "0" }}>Instagram</p>
          </div>
          <div style={{ margin: "1vw 0", display: "flex", flexDirection: "row", justifyContent: "left" }}>
            <img style={{ height: "3.5vh", width: "3.5vh" }} src="https://cdn-icons-png.flaticon.com/512/20/20673.png" alt="" />
            <p style={{ paddingLeft: "3vh", margin: "0" }}>Facebook</p>
          </div>
        </div>
      </div>
    </div>
  );
}
