import "./App.css";
import Nav from "./components/nav/nav";
import React, { useState } from "react";
// import About from "./routes/about.routes";
// import Advice from "./routes/advice.routes";
// import Careers from "./routes/careers.routes";
// import Products from "./routes/products.routes";
import DashBoard from "./routes/dashboard";
import { Route, BrowserRouter, Routes } from "react-router-dom";
import Popup from "./components/popup/popup";
import Contact from "./components/forms/contact.form";

function App() {
  const [showPopUp, setShowPopUp] = useState(false);
  const closePopup = () => {
    setShowPopUp(!showPopUp);
  };
  return (
    <BrowserRouter>
      <Nav close={closePopup} />
      <div>
        <Routes>
          {/* <Route path="products" element={Products()}></Route>
          <Route path="about" element={About()}></Route>
          <Route path="advice" element={Advice()}></Route>
          <Route path="careers" element={Careers()}></Route> */}
          <Route path="*" element={DashBoard({ close: closePopup })}></Route>
        </Routes>
        <Popup show={showPopUp} close={closePopup}>
          <div style={{ margin: "0 6vw", display: "flex", flexDirection: "column", justifyContent: "center", textAlign: "center" }}>
            <h2>Get Quote!</h2>
            <p style={{ color: "#888", fontSize: "1.9vh" }}>
              Not ready to buy a policy Run your numbers on our car insurance calculator to estimate how much your policy will cost.
            </p>
            <Contact close={closePopup} />
          </div>
        </Popup>
        <footer style={{ padding: "0 3vw" }}>
          <hr style={{ backgroundColor: "#615a5a", height: "0.25vw" }} />
          <p style={{ fontSize: "1.5vh", fontWeight: "bold", color: "#615a5a" }}> © 2022. All Rights Reserved by SACAPITAL.co.in</p>
        </footer>
      </div>
    </BrowserRouter>
  );
}

export default App;
