import React from "react";
import "./popup.css";
export default function Popup(props: any) {
  return props.show ? (
    <div className="popup">
      <div className="box">
        <p
          style={{
            cursor: "pointer",
            color: "red",
            fontSize: "2vh",
            fontWeight: "bold",
            padding: "2vh",
            margin: "0",
            float: "right",
          }}
          onClick={props.close}
        >
          X
        </p>
        {props.children}
      </div>
    </div>
  ) : null;
}
