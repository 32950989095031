import React, { useEffect, useState } from "react";
import "./contact.css";

export default function Contact(props: any) {
  const [email, setEmail] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [phone_number, setPhone_number] = useState<string>("");
  const [vehicleNumber, setVehicleNumber] = useState("");
  const [btactive, setBtactive] = useState(true);
  useEffect(() => {
    // console.log({ name, email, phone_number });
    if (email !== "" && name !== "" && phone_number !== "") {
      setBtactive(false);
    }
  }, [name, email, phone_number]);

  return (
    <form action="/" method="POST" name="contact" data-netlify="true" netlify-honeypot="bot-field">
      <input id="Input" type="hidden" name="form-name" value="contact" />
      <input id="Input" type="text" placeholder="Name" name="name" value={name} onChange={(e) => setName(e.target.value)} />
      <input id="Input" type="number" placeholder="Phone number" name="Phone Number" value={phone_number} onChange={(e) => setPhone_number(e.target.value)} />
      <input id="Input" type="email" placeholder="E-Mail" name="E-Mail" value={email} onChange={(e) => setEmail(e.target.value)} />
      <input id="Input" type="text" placeholder="Vehicle Number" name="Vehicle Number" value={vehicleNumber} onChange={(e) => setVehicleNumber(e.target.value)} />
      <button
        disabled={btactive}
        type="submit"
        style={{
          margin: "2vh 5vh",
          alignSelf: "center",
          backgroundColor: "#e60b0b",
          width: "16vh",
          height: "35px",
          borderRadius: "0.75vw",
          cursor: "pointer",
          border: "0",
        }}
      >
        <p style={{ color: "white", fontSize: "2vh", alignSelf: "center", margin: "0" }}>Submit</p>
      </button>
    </form>
  );
}
